import { ApolloError } from '@apollo/client'

import { DeviceType } from '@/graphql/generated/schemas'

import { Options } from '../../types/types'

const MIME_TYPE = 'text/csv'
export const DEVICE_TOAST_DURATION = 4000

export const deviceTypes: Options = [
  { label: 'Alarm', value: DeviceType.Alarm },
  { label: 'Camera', value: DeviceType.Camera },
  { label: 'Door', value: DeviceType.Door },
]

export const isValidFileType = (fl: FileList): boolean => {
  if (fl?.length === 0) {
    return true
  }
  return fl?.[0]?.type === MIME_TYPE
}

export const getErrorMessage = (e: ApolloError) => {
  const errorDetails = e?.graphQLErrors?.[0]?.extensions
  if (errorDetails?.code?.startsWith('csv.row')) {
    const rowNumber: number = errorDetails.details.rowIndex + 1
    return `Error in row ${rowNumber}: ${e.message}`
  } else {
    return e?.message ?? 'Cannot create devices'
  }
}

export const getDeviceTypeMessage = (type: DeviceType) => {
  switch (type) {
    case DeviceType.Alarm:
      return 'alarms'
    case DeviceType.Camera:
      return 'cameras'
    case DeviceType.Door:
      return 'doors'
    default:
      return 'devices'
  }
}

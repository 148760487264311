import React, { useEffect } from 'react'

import { useEditDoor } from '../hooks/useEditDoor'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { Box, ModalBody, ModalFooter } from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  SkeletonModalBody,
} from '@/components/ui'

import { DeviceModalHeader } from '../../components/DeviceModalHeader'
import { DeviceTable } from '../../components/DeviceTable'
import { validateMac } from '../../edit-camera/utils/utils'
import { FormDataTypeI } from '../../types/types'
import { isPlaced } from '../../utils/utils'

interface EditDoorFormIProps {
  onClose: () => void
  deviceId: string
}

export const EditDoorForm = ({ onClose, deviceId }: EditDoorFormIProps) => {
  const {
    handleSubmit,
    errors,
    register,
    control,
    formState,
    setValue,
    trigger,
  } = useForm<FormDataTypeI>({
    mode: 'onChange',
  })
  const { shouldEnableUpdateDevice } = usePermissions()
  const { isValid } = formState
  const {
    deviceData,
    isLoading,
    floorsOptions,
    isUpdateDeviceLoading,
    isUpdateDoorLoading,
    updateDoor,
  } = useEditDoor(deviceId)
  const { showSuccess, showError } = useToasts()

  const onSubmit: SubmitHandler<FormDataTypeI> = async (values) => {
    const formData = {
      id: deviceId,
      name: values.name,
      floorId: values.location.value,
      macAddress: values.macAddress.length > 0 ? values.macAddress : null,
    }

    try {
      await updateDoor(formData, values.externalId)
      showSuccess('Device updated.')
      onClose()
    } catch (e) {
      showError()
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setValue('name', deviceData?.device?.name, { shouldValidate: true })
      setValue(
        'location',
        floorsOptions?.find((i) => i.value === deviceData?.device?.floor?.id),
        {
          shouldValidate: true,
        }
      )
      setValue('macAddress', deviceData?.device?.macAddress)
      setValue('externalId', deviceData?.device?.door?.externalId)
      trigger(['name', 'macAddress'])
    }
  }, [isLoading, floorsOptions])

  return (
    <>
      <DeviceModalHeader
        subTitle='Enter Device Info to connect to a Device'
        title='Edit Door'
      />
      <ModalBody p='0'>
        {!deviceData || isLoading ? (
          <SkeletonModalBody />
        ) : (
          <>
            <form>
              <Box px='6'>
                <Box mb='3'>
                  <FormInputControl
                    data-testid='devicesPage_editDeviceModal_name'
                    errorMessage={errors.name && errors.name.message}
                    id='name'
                    inputRef={register({
                      required: 'Device Name is required',
                    })}
                    isInvalid={!!errors.name}
                    label='Device Name'
                    placeholder='Enter the Device Name'
                  />
                </Box>
                <Box mb='3'>
                  <FormInputSelectControl
                    control={control}
                    data-testid='devicesPage_editDeviceModal_location'
                    defaultValue={null}
                    errorMessage={
                      !!errors?.location && 'Select a device location'
                    }
                    id='location'
                    isClearable
                    isDisabled={isLoading || isPlaced(deviceData?.device)}
                    isInvalid={!!errors?.location}
                    label='Device Location'
                    options={floorsOptions}
                    placeholder='Select Device Location'
                    rules={{ required: true }}
                    tooltipText={
                      isPlaced(deviceData?.device) &&
                      'Device must not be placed to update device location'
                    }
                  />
                </Box>
                <Box mb='3'>
                  <FormInputControl
                    data-testid='devicesPage_editDeviceModal_macAddress'
                    errorMessage={
                      errors.macAddress && errors.macAddress.message
                    }
                    id='macAddress'
                    inputRef={register({
                      validate: (v) => validateMac(v),
                      required: false,
                    })}
                    isInvalid={!!errors.macAddress}
                    label='Enter Device Device ID / MAC Address'
                    placeholder='Enter Device ID / MAC Address'
                  />
                </Box>
                <Box mb='3'>
                  <FormInputControl
                    data-testid='devicesPage_editDeviceModal_externalId'
                    errorMessage={errors.externalId?.message}
                    id='externalId'
                    inputRef={register()}
                    label='External ID'
                    placeholder='Enter External ID'
                    tooltipText='This associates the device with a camera'
                  />
                </Box>
              </Box>
            </form>
            <DeviceTable devices={[deviceData?.device as unknown]} />
          </>
        )}
      </ModalBody>
      <ModalFooter py='4'>
        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!isValid || !shouldEnableUpdateDevice}
          isLoading={isUpdateDeviceLoading || isUpdateDoorLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          loadingText='Saving Changes'
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </ModalFooter>
    </>
  )
}

import { useMemo } from 'react'

import { useToasts } from '@/hooks/useToasts'

import {
  useDeviceEditQuery,
  useFloorsQuery,
  useUpdateDeviceMutation,
} from '@/graphql/generated/hooks'

import { FormDataTypeI, UpdateDeviceInputsI } from '../../types/types'
import { getFloorOptions } from '../../utils/utils'

interface UseEditAlarmIProps {
  deviceId: string
  onClose: () => void
}

export const useEditAlarm = ({ deviceId, onClose }: UseEditAlarmIProps) => {
  const { showSuccess, showError } = useToasts()
  const { data: floorsData, loading: isFloorsLoading } = useFloorsQuery({
    fetchPolicy: 'network-only',
  })
  const [updateDevice, { loading: isUpdateDeviceLoading }] =
    useUpdateDeviceMutation()
  const {
    data: alarmData,
    loading: isAlarmLoading,
    error: alarmError,
  } = useDeviceEditQuery({
    variables: {
      id: deviceId,
    },
    fetchPolicy: 'network-only',
  })
  const update = async (formData: FormDataTypeI) => {
    const input: UpdateDeviceInputsI = {
      id: deviceId,
      name: formData.name,
      floorId: formData.location?.value,
    }
    try {
      await updateDevice({
        variables: { input },
        refetchQueries: ['Devices'],
      })
      showSuccess('Alarm updated.')
      onClose()
    } catch (e) {
      showError(e.message)
    }
  }

  const floorsOptions = useMemo(() => getFloorOptions(floorsData), [floorsData])

  return {
    alarmData,
    alarmError,
    isLoading: isAlarmLoading || isFloorsLoading,
    isUpdateDeviceLoading,
    floorsOptions,
    update,
  }
}

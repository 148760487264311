import { useMemo, useState } from 'react'

import { useToasts } from '@/hooks/useToasts'
import { MAX_CSV_SIZE, MAX_FILENAME_LENGTH } from '@/hooks/useUpload'
import { useUpload } from '@/hooks/useUpload/useUpload'

import {
  useCreateDeviceMutation,
  useCreateDevicesMutation,
  useFacilityFloorsQuery,
  useFacilityNamesQuery,
} from '@/graphql/generated/hooks'
import { DeviceStatus, UploadedFileType } from '@/graphql/generated/schemas'
import {
  getFacilityOptions,
  getFloorOptions,
} from '@/utils/forms/optionBuilders'

import { FormInputsIProps } from '../types/types'
import {
  DEVICE_TOAST_DURATION,
  getDeviceTypeMessage,
  getErrorMessage,
} from '../utils/utils'

interface UseCreateDevicesIProps {
  onClose: () => void
  facilityId: string
}

export const useCreateDevices = ({
  facilityId,
  onClose,
}: UseCreateDevicesIProps) => {
  const [upload] = useUpload()
  const [createDevices] = useCreateDevicesMutation()
  const [createDevice] = useCreateDeviceMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [isBulkUploadSelected, setIsBulkUploadSelected] = useState(false)
  const { showSuccess, showError } = useToasts({
    toastDuration: DEVICE_TOAST_DURATION,
  })
  const { data: facilitiesData, loading: isFacilitiesLoading } =
    useFacilityNamesQuery({
      fetchPolicy: 'network-only',
    })
  const { data: floorsData, loading: isFloorsLoading } = useFacilityFloorsQuery(
    {
      variables: {
        id: facilityId,
      },
      fetchPolicy: 'network-only',
      skip: !facilityId,
    }
  )
  const facilityOptions = useMemo(
    () => getFacilityOptions(facilitiesData),
    [facilitiesData]
  )

  const floorOptions = useMemo(() => getFloorOptions(floorsData), [floorsData])

  const create = async (values: FormInputsIProps) => {
    if (!isBulkUploadSelected) {
      createGenericDevice(values)
    } else {
      createBulkDevices(values)
    }
  }

  const createBulkDevices = async (values: FormInputsIProps) => {
    const file = values.file.item(0)
    const type = values.type.value

    try {
      setIsLoading(true)
      const fileId = await upload({
        file,
        fileType: UploadedFileType.CsvFile,
        validationOptions: {
          maxFileSizeInBytes: MAX_CSV_SIZE,
          maxFileNameLength: MAX_FILENAME_LENGTH,
        },
      })
      const resp = await createDevices({
        variables: {
          input: {
            deviceType: type,
            sheetExternalId: fileId,
          },
        },
        refetchQueries: [
          'Devices',
          'FloorDevicesCount',
          'FacilityDevicesByFloors',
        ],
      })
      const count = resp?.data?.createDevices?.length
      showSuccess(`Successfully created ${count} ${getDeviceTypeMessage(type)}`)
      setIsLoading(false)
      onClose()
    } catch (e) {
      showError(getErrorMessage(e))
      setIsLoading(false)
    }
  }

  const createGenericDevice = async (values: FormInputsIProps) => {
    const type = values.type.value
    try {
      setIsLoading(true)
      await createDevice({
        variables: {
          input: {
            type: type,
            name: values.name,
            facilityId: values.facility.value,
            floorId: values.floor.value,
            status: DeviceStatus.Healthy,
          },
        },
        refetchQueries: [
          'Devices',
          'FloorDevicesCount',
          'FacilityDevicesByFloors',
        ],
      })
      showSuccess(`Successfully created ${values.name}`)
      setIsLoading(false)
      onClose()
    } catch (e) {
      showError(getErrorMessage(e))
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    facilityOptions,
    floorOptions,
    isOptionsLoading: isFloorsLoading && isFacilitiesLoading,
    create,
    setIsBulkUploadSelected,
  }
}

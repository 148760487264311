import { useDeleteDevice } from '../hooks/useDeleteDevice'
import { usePermissions } from '@/hooks/usePermissions'
import { HiExclamation, HiOutlineTrash } from 'react-icons/hi'

import { AlertDialogBody, AlertDialogFooter, Box, Flex } from '@chakra-ui/react'

import { BasicAlertDialog, Button } from '@/components/ui'
import { DeviceType } from '@/graphql/generated/schemas'

import { getDeviceTypeMessage } from '../utils/utils'

interface DeleteCheckPointModalIProps {
  isOpen: boolean
  onClose: () => void
  deviceId: string
  deviceType: DeviceType
}

export const DeleteDeviceModal = ({
  isOpen,
  onClose,
  deviceId,
  deviceType,
}: DeleteCheckPointModalIProps) => {
  const { shouldEnableDeleteDevice } = usePermissions()
  const device = getDeviceTypeMessage(deviceType)
  const { deleteDevice, isLoading } = useDeleteDevice({
    deviceId,
    device,
    onClose,
  })
  return (
    <BasicAlertDialog
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title={`Deleting ${device}`}
    >
      <AlertDialogBody>
        <Box
          color='#2d2e41'
          data-testid='DeleteDeviceModal:message'
          fontSize='16px'
          fontWeight='500'
          letterSpacing='-0.53px'
        >
          {`Are you sure you want to delete this ${device}.`}
        </Box>
        <Flex alignItems='center' color='#D11D3A' flexDirection='row' pt='4'>
          <HiExclamation size='24px' />
          <Box fontSize='16px' fontWeight='medium' ml='1'>
            <Box as='span' fontWeight='bold'>
              Warning:
            </Box>{' '}
            All deletions are permanent
          </Box>
        </Flex>
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button
          data-testid='DeleteDeviceModal:cancel'
          mr='3'
          onClick={onClose}
          variant='ghost'
        >
          Cancel
        </Button>
        <Button
          data-testid='DeleteDeviceModal:confirm'
          isDisabled={isLoading || !shouldEnableDeleteDevice}
          isLoading={isLoading}
          leftIcon={<HiOutlineTrash size='24px' />}
          onClick={deleteDevice}
          variant='danger'
        >
          Delete
        </Button>
      </AlertDialogFooter>
    </BasicAlertDialog>
  )
}

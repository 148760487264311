import React from 'react'

import { useCreateDevices } from '../hooks/useCreateDevices'
import { usePermissions } from '@/hooks/usePermissions'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

import { Button } from '@/components/ui'

import { DeviceModalHeader } from '../../components/DeviceModalHeader'
import { FormInputsIProps } from '../types/types'
import { BulkUploadForm } from './BulkUploadForm'
import { CreateDeviceForm } from './CreateDeviceForm'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateDeviceModal = ({ isOpen, onClose }: IProps) => {
  const { shouldEnableCreateDevice } = usePermissions()
  const form = useForm<FormInputsIProps>({ mode: 'onChange' })
  const { handleSubmit, watch, formState } = form

  const { isValid } = formState
  const {
    create,
    setIsBulkUploadSelected,
    facilityOptions,
    floorOptions,
    isOptionsLoading,
    isLoading,
  } = useCreateDevices({ onClose, facilityId: watch('facility')?.value })

  return (
    <Modal
      id='createDeviceModal'
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='outside'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' rounded='20px'>
        <ModalCloseButton color='#000' opacity={0.4} />
        <DeviceModalHeader title='Create Device' />
        <ModalBody p='0'>
          <Tabs
            bgColor='#FFFFFF'
            borderColor='transparent'
            fontWeight='500'
            isLazy
            onChange={(index) => setIsBulkUploadSelected(index !== 0)}
            px='10'
          >
            <TabList>
              <Tab
                _selected={{
                  color: '#005BBF',
                  borderBottomColor: '#005BBF',
                  opacity: 1,
                }}
                opacity={0.4}
              >
                <Box alignItems='center' display='inline-flex'>
                  Create Individual
                </Box>
              </Tab>
              <Tab
                _selected={{
                  color: '#005BBF',
                  borderBottomColor: '#005BBF',
                  opacity: 1,
                }}
                opacity={0.4}
              >
                <Box alignItems='center' display='inline-flex'>
                  Bulk Upload
                </Box>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel mt='5px' px='0'>
                <CreateDeviceForm
                  facilityOptions={facilityOptions}
                  floorOptions={floorOptions}
                  form={form}
                  isOptionsLoading={isOptionsLoading}
                />
              </TabPanel>
              <TabPanel mt='5px' px='0'>
                <BulkUploadForm form={form} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter py='4'>
          <Button mr='3' onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!isValid || !shouldEnableCreateDevice}
            isLoading={isLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Creating Devices'
            onClick={handleSubmit(create)}
          >
            Create Device
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import React from 'react'

import { UseFormMethods } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import { FormInputControl, FormInputSelectControl } from '@/components/ui'

import { Options } from '../../types/types'
import { FormInputsIProps } from '../types/types'

interface GenericDeviceFormIProps {
  form: UseFormMethods<FormInputsIProps>
  facilityOptions: Options
  floorOptions: Options
  isOptionsLoading: boolean
}

export const GenericDeviceForm = ({
  form,
  isOptionsLoading,
  facilityOptions,
  floorOptions,
}: GenericDeviceFormIProps) => {
  const { control, errors, register } = form
  return (
    <Box>
      <Box mb='3'>
        <FormInputControl
          data-testid='devicesPage_genericModal_name'
          errorMessage={errors.name && errors.name.message}
          id='name'
          inputRef={register({
            required: 'Device Name is required',
          })}
          isInvalid={!!errors.name}
          label='Device Name'
          placeholder='Enter the Device Name'
        />
      </Box>
      <Box mb='3'>
        <FormInputSelectControl
          control={control}
          data-testid='devicesPage_genericModal_facility'
          defaultValue={null}
          errorMessage='Select a facility'
          id='facility'
          isClearable
          isDisabled={isOptionsLoading}
          isInvalid={!!errors?.facility}
          label='Facility'
          options={facilityOptions}
          placeholder='Select Device Facility'
          rules={{ required: true }}
        />
      </Box>

      <Box mb='3'>
        <FormInputSelectControl
          control={control}
          data-testid='devicesPage_genericModal_floor'
          defaultValue={null}
          errorMessage='Select a floor'
          id='floor'
          isClearable
          isDisabled={isOptionsLoading || floorOptions.length === 0}
          isInvalid={!!errors?.floor}
          label='Floor'
          options={floorOptions}
          placeholder='Select Device Floor'
          rules={{ required: true }}
        />
      </Box>
    </Box>
  )
}

import { UseFormMethods } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import { FormInputSelectControl } from '@/components/ui'

import { Options } from '../../types/types'
import { FormInputsIProps } from '../types/types'
import { deviceTypes } from '../utils/utils'
import { GenericDeviceForm } from './GenericDeviceForm'

interface CreateDeviceFormIProps {
  form: UseFormMethods<FormInputsIProps>
  facilityOptions: Options
  floorOptions: Options
  isOptionsLoading: boolean
}
export const CreateDeviceForm = ({
  form,
  isOptionsLoading,
  facilityOptions,
  floorOptions,
}: CreateDeviceFormIProps) => {
  const { control, errors } = form
  return (
    <Box>
      <Box mb='3'>
        <FormInputSelectControl
          control={control}
          errorMessage={!!errors?.type && 'Select a device type'}
          id='type'
          isClearable
          isInvalid={!!errors?.type}
          label='Device Type'
          options={deviceTypes}
          placeholder='Device Type Selection'
          rules={{ required: true }}
        />
      </Box>

      <GenericDeviceForm
        facilityOptions={facilityOptions}
        floorOptions={floorOptions}
        form={form}
        isOptionsLoading={isOptionsLoading}
      />
    </Box>
  )
}

import React from 'react'

import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'

import { DeviceType } from '@/graphql/generated/schemas'

import { EditAlarmForm } from '../edit-alarm/components/EditAlarmForm'
import { EditCameraForm } from '../edit-camera/components/EditCameraForm'
import { EditDoorForm } from '../edit-door/components/EditDoorForm'

interface EditDeviceModalIProps {
  onClose: () => void
  isOpen: boolean
  deviceId: string
  deviceType: DeviceType
}

export const EditDeviceModal = ({
  onClose,
  isOpen,
  deviceId,
  deviceType,
}: EditDeviceModalIProps) => {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='inside'
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalCloseButton color='#000' opacity={0.4} />
        {deviceType === DeviceType.Alarm && (
          <EditAlarmForm deviceId={deviceId} onClose={onClose} />
        )}
        {deviceType === DeviceType.Camera && (
          <EditCameraForm deviceId={deviceId} onClose={onClose} />
        )}
        {deviceType === DeviceType.Door && (
          <EditDoorForm deviceId={deviceId} onClose={onClose} />
        )}
      </ModalContent>
    </Modal>
  )
}

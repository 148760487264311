import { useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { DeviceType } from '@/graphql/generated/schemas'

export const useDeviceModals = () => {
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const [deviceType, setDeviceType] = useState<DeviceType>(null)
  const {
    isOpen: isEditDeviceOpen,
    onOpen: onEditDeviceOpen,
    onClose: onEditDeviceClose,
  } = useDisclosure()
  const {
    isOpen: isDeleteDeviceOpen,
    onOpen: onDeleteDeviceOpen,
    onClose: onDeleteDeviceClose,
  } = useDisclosure()

  const openEditDevice = (deviceId: string, type: DeviceType) => {
    setDeviceId(deviceId)
    setDeviceType(type)
    onEditDeviceOpen()
  }

  const openRemoveDevice = (deviceId: string, type: DeviceType) => {
    setDeviceId(deviceId)
    setDeviceType(type)
    onDeleteDeviceOpen()
  }

  return {
    deviceId,
    deviceType,
    isEditDeviceOpen,
    onEditDeviceClose,
    isDeleteDeviceOpen,
    openEditDevice,
    openRemoveDevice,
    onDeleteDeviceClose,
  }
}

import React, { useEffect } from 'react'

import { useEditAlarm } from '../hooks/useEditAlarm'
import { usePermissions } from '@/hooks/usePermissions'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import { Box, ModalBody, ModalFooter } from '@chakra-ui/react'

import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  SkeletonModalBody,
} from '@/components/ui'

import { DeviceModalHeader } from '../../components/DeviceModalHeader'
import { CameraFormDataTypeI } from '../../types/types'
import { isPlaced } from '../../utils/utils'

interface EditAlarmFormIProps {
  onClose: () => void
  deviceId: string
}

export const EditAlarmForm = ({ deviceId, onClose }: EditAlarmFormIProps) => {
  const { shouldEnableUpdateDevice } = usePermissions()
  const { alarmData, isLoading, isUpdateDeviceLoading, floorsOptions, update } =
    useEditAlarm({
      deviceId,
      onClose,
    })
  const form = useForm<CameraFormDataTypeI>({
    mode: 'onChange',
  })
  const { handleSubmit, errors, register, control, setValue } = form

  useEffect(() => {
    if (!isLoading) {
      setValue('name', alarmData?.device?.name, { shouldValidate: true })
      setValue(
        'location',
        floorsOptions?.find((i) => i.value === alarmData?.device?.floor?.id),
        {
          shouldValidate: true,
        }
      )
    }
  }, [isLoading, floorsOptions])

  return (
    <>
      <DeviceModalHeader
        subTitle='Enter Device Info to connect to a Device'
        title='Edit Panic Alarm'
      />
      <ModalBody p='0'>
        {!alarmData || isLoading ? (
          <SkeletonModalBody />
        ) : (
          <form>
            <Box px='6'>
              <Box mb='3'>
                <FormInputControl
                  data-testid='devicesPage_editAlarmModal_name'
                  errorMessage={errors.name && errors.name.message}
                  id='name'
                  inputRef={register({
                    required: 'Device Name is required',
                  })}
                  isInvalid={!!errors.name}
                  label='Device Name'
                  placeholder='Enter the Device Name'
                />
              </Box>
              <Box mb='3'>
                <FormInputSelectControl
                  control={control}
                  data-testid='devicesPage_editAlarmModal_location'
                  defaultValue={null}
                  errorMessage='Select a device location'
                  id='location'
                  isClearable
                  isDisabled={isPlaced(alarmData?.device)}
                  isInvalid={!!errors?.location}
                  label='Device Location'
                  options={floorsOptions}
                  placeholder='Select Device Location'
                  rules={{ required: true }}
                  tooltipText={
                    isPlaced(alarmData?.device) &&
                    'Device must not be placed to update device location'
                  }
                />
              </Box>
            </Box>
          </form>
        )}
      </ModalBody>
      <ModalFooter py='4'>
        <Button mr='3' onClick={onClose} variant='secondary'>
          Cancel
        </Button>
        <Button
          isDisabled={!shouldEnableUpdateDevice}
          isLoading={isUpdateDeviceLoading}
          leftIcon={<HiOutlineCheckCircle size='24px' />}
          loadingText='Saving Changes'
          onClick={handleSubmit(update)}
        >
          Save Changes
        </Button>
      </ModalFooter>
    </>
  )
}

import { UseFormMethods } from 'react-hook-form'

import { Box, Tooltip } from '@chakra-ui/react'

import {
  FormFileUploadControl,
  FormInputSelectControl,
  StyledFormInputControl,
} from '@/components/ui'

import { FormInputsIProps } from '../types/types'
import { deviceTypes, isValidFileType } from '../utils/utils'

interface CreateDeviceFormIProps {
  form: UseFormMethods<FormInputsIProps>
}
export const BulkUploadForm = ({ form }: CreateDeviceFormIProps) => {
  const { register, control, errors, watch, trigger } = form
  const fileUpload = watch('file')
  return (
    <Box>
      <Box mb='3'>
        <FormInputSelectControl
          control={control}
          errorMessage={!!errors?.type && 'Select a device type'}
          id='type'
          isClearable
          isInvalid={!!errors?.type}
          label='Device Type'
          options={deviceTypes}
          placeholder='Device Type Selection'
          rules={{ required: true }}
        />
      </Box>

      <StyledFormInputControl mb={3}>
        <Tooltip label='Supported file types include CSV' shouldWrapChildren>
          <FormFileUploadControl
            accept='.csv'
            dataTestId='createFloor_modal_floorplan'
            errorMessage={
              fileUpload &&
              !isValidFileType(fileUpload) &&
              'File should be in .csv format'
            }
            id='file'
            inputRef={register({ required: true })}
            isInvalid={!isValidFileType(fileUpload)}
            label='Devices'
            onFileRemoved={() => trigger(['file'])}
            placeholder='Attach CSV'
          />
        </Tooltip>
      </StyledFormInputControl>
    </Box>
  )
}
